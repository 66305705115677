const initialState = {
	newUsers: [],
	members: [],
	member: {},
	referrals: [],
	commodityReq: [],
	approvedCommodityRequests: [],
	pendingCommodityRequests: [],
	products: [],
	loanRequests: [],
	legalDocs: [],
	finReports: [],
	memberSearch: [],
	isLoading: true,
	loanRequestPageNum: 1,
	pendingCommodityRequestsPageNum: 1,
	approvedCommodityRequestsPageNum: 1,
};

const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_MEMBERS":
			return {
				...state,
				members: action?.payload,
			};
		case "GET_REFERRALS":
			return {
				...state,
				referrals: action.payload,
			};
		case "GET_PRODUCTS":
			return {
				...state,
				products: action.payload,
			};

		case "GET_COMMODITY_REQUEST":
			if (action.payload.status === "pending") {
				console.log(action.payload.data.data)
				return {
					...state,
					pendingCommodityRequests: action.payload.data.data,
					pendingCommodityRequestsPageNum: action.payload?.data.totalPages,
					isLoading: false,

				};
			} else if (action.payload.status === "approved") {
				return {
					...state,
					approvedCommodityRequests: action.payload.data.data,
					approvedCommodityRequestsPageNum: action.payload?.data.totalPages,
					isLoading: false,

				};
			}
			return state;
		case "GET_LOAN_REQUEST":
			return {
				...state,
				loanRequests: action.payload.loanRequests,
				loanRequestPageNum: action.payload?.numberOfPages
			};
		case "GET_LEGAL_DOC":
			return {
				...state,
				legalDocs: action.payload,
			};
		case "GET_FIN_REPORT":
			return {
				...state,
				finReports: action.payload,
			};
		case "NEW_REGISTRATION":
			return {
				...state,
				newUsers: action.payload,
				isLoading: false,
			};
		case "GET_MEMBERS_SEARCH":
			return {
				...state,
				memberSearch: action.payload.users,
			};
		case "GET_MEMBER_BY_ID":
			return {
				...state,
				member: action.payload,
			};
		case "USER_UPDATE_SUCCESS":
			return {
				...state,
				member: action.payload,
			};
		case "UPDATED_BANK_INFO":
			return {
				...state,
				member: {
					...state.member,
					bankDetails: action.payload,
				},
			};
		case "UPDATE_MEMBER_FEATURE_TOGGLE":
			return {
				...state,
				member: { ...state.member, featureToggles: { ...state.member.featureToggles, ...action.payload.user.featureToggles } }

			};

		case "CONFIRM_USER_SUCCESSFUL":
			return {
				...state,
				newUsers: state.newUsers.filter(
					(user) => user._id !== action.payload.userId
				),
			};
		case "DELETE_USER_SUCCESSFUL":
			return {
				...state,
				newUsers: state.newUsers.filter(
					(user) => user._id !== action.payload.userId
				),
			};
		case "SET_LOADING_FALSE":
			return {
				...state,
				isLoading: false,
			};
		case "SET_LOADING_TRUE":
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;
	}
};

export default adminReducer;
