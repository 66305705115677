import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCheck, FaTimes } from 'react-icons/fa';

function FeatureToggle({ feature, enabled, onToggle }) {
    const handleToggle = () => {
        onToggle(feature, !enabled);
    };

    return (
        <div className="d-flex justify-content-between align-items-center mb-3 p-2 border rounded">
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-${feature}`}>{enabled ? 'Disable' : 'Enable'} {feature}</Tooltip>}
            >
                <span className="font-weight-bold">{feature}</span>
            </OverlayTrigger>
            <Form.Check
                type="switch"
                id={`toggle-${feature}`}
                checked={enabled}
                onChange={handleToggle}
                label={enabled ? <FaCheck color="green" /> : <FaTimes color="red" />}
                className="custom-toggle"
            />
        </div>
    );
}

export default FeatureToggle;
