import React from "react";
import {
	ButtonGroup,
	Container,
	Form,
	Row,
	ToggleButton,
	Button,
	Alert,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchLoanee } from "../store/actions/userActions";
import SimpleHeader from "./SimpleHeader";
import { useHistory } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import ImagePreview from "./utilities/ImagePreview";
import Preloader from "./utilities/Preloader";

function Guarantor() {
	const dispatch = useDispatch();
	const history = useHistory();
	const queryString = window.location.search;
	const urlParam = new URLSearchParams(queryString);
	const loanId = urlParam.get("loanid");

	const loanee = useSelector((state) => state.userProfile.loanee);
	const { isLoading } = useSelector((state) => state.userProfile);

	const [inputValue, setInputValue] = React.useState({
		surname: "",
		otherName: "",
		residentialAddress: "",
		telephone: "",
		email: "",
		workPlace: "",
		permanentAddress: "",
		nok: "",
		nokPhone: "",
		nokEmail: "",
	});
	const [error, setError] = React.useState({});
	const [isMember, setIsMember] = React.useState("false");
	const [imageURL, setImageURL] = React.useState(null);
	const [show, setShow] = React.useState(false);
	const [imageFile, setFile] = React.useState({
		passport: null,
		signature: null,
	});

	React.useEffect(() => {
		dispatch(fetchLoanee(loanId));
	}, [dispatch, loanId]);

	const addImage = async (e) => {
		const file = e?.target?.files[0];
		const fileSize = file.size / 1024 / 1024;
		setFile({
			...imageFile,
			[[e?.target?.name] + "Error"]: null,
		});

		if (fileSize.toFixed(2) > 1) {
			setFile({
				...imageFile,
				[[e?.target?.name] + "Error"]: "file cannot exceed 1mb",

				[e?.target?.name]: null,
			});
		} else {
			const reader = new FileReader();
			reader.onloadend = () => {
				setFile({
					...imageFile,
					[[e?.target?.name] + "URL"]: URL.createObjectURL(file),
					[e?.target?.name]: reader.result,
					[[e?.target?.name] + "Error"]: null,
				});

				setShow(false);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleChange = (e) => {
		setInputValue({ ...inputValue, [e.target.name]: e.target.value });
	};

	const sigCanvas = React.useRef({});

	const saveCanvas = () => {
		setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
		setShow(false);
		if (sigCanvas.current.isEmpty()) {
			setImageURL(null);
			setShow(true);
		}
	};

	const findError = () => {
		const {
			surname,
			otherName,
			residentialAddress,
			telephone,
			email,
			workPlace,
			permanentAddress,
			nok,
			nokPhone,
			nokEmail,
			shareCapital,
			ordinarySavings,
		} = inputValue;
		const { passport, signature } = imageFile;
		const newErrors = {};
		if (imageURL === null && signature === null)
			newErrors.imageURL = "signature cannot be blank";
		if (!surname || surname === "")
			newErrors.surname = "surname cannot be blank";
		if (!otherName || otherName === "")
			newErrors.otherName = "otherName cannot be blank";
		if (!residentialAddress || residentialAddress === "")
			newErrors.residentialAddress = "residentialAddress cannot be blank";

		if (!telephone || telephone === "")
			newErrors.telephone = "telephone cannot be blank";

		if (!email || email === "") newErrors.email = "email cannot be blank";

		if (!workPlace || workPlace === "")
			newErrors.workPlace = "workPlace cannot be blank";

		if (!permanentAddress || permanentAddress === "")
			newErrors.permanentAddress = "permanentAddress cannot be blank";

		if (!nok || nok === "") newErrors.nok = "nok cannot be blank";

		if (!nokEmail || nokEmail === "")
			newErrors.nokEmail = "nokEmail cannot be blank";

		if (!nokPhone || nokPhone === "")
			newErrors.nokPhone = "nokPhone cannot be blank";
		if (passport === null) newErrors.passport = "passport cannot be blank";

		if (isMember === "true") {
			if (!shareCapital || shareCapital === "")
				newErrors.shareCapital = "shareCapital cannot be blank";
			if (!ordinarySavings || ordinarySavings === "")
				newErrors.ordinarySavings = "ordinarySavings cannot be blank";
		}

		return newErrors;
	};

	const handlePreview = (e) => {
		e.preventDefault();
		const newError = findError();
		if (imageURL === null && imageFile.signature === null) {
			setShow(true);
		}
		if (Object.keys(newError).length > 0) {
			setError(newError);
		} else {
			inputValue.isMember = isMember;
			inputValue.loanId = loanId;
			inputValue.imageURL = imageURL || imageFile.signature;
			inputValue.passport = imageFile.passport;
			history.push({
				pathname: "/guarantor-preview",
				state: inputValue,
			});
		}
	};

	if (isLoading) return <Preloader />;

	if (!loanee && !isLoading)
		return (
			<Alert variant='danger' className='mt-4'>
				<h5>something went wrong!!... please check the URL</h5>
				<p>
					URL should carry a valid loan ID eg
					"http://lmcsnigltd.org.ng/guarantor-form/?loanid=LOAN-MF8MKZMFLFD8"
				</p>
			</Alert>
		);
	return (
		<>
			<SimpleHeader />
			{!isLoading ? (
				<Container>
					<div className='card' id='cart'>
						<div className='row'>
							<div className='col-md-12 cart'>
								<div className='title'>
									<div className='row'>
										<div className='col'>
											<h4>
												<b>LOAN GUARANTOR'S FORM </b>
											</h4>
											LOANEE:{loanee?.loaneeDetails?.name}({loanee?.userId})
											<p>LOAN ID: {loanee?.transactionId}</p>
										</div>
									</div>
								</div>

								<Form>
									<Row>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Surname</Form.Label>
											<Form.Control
												type='text'
												name='surname'
												placeholder='enter surname'
												value={inputValue.surname}
												onChange={handleChange}
												isInvalid={!!error.surname}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.surname}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Other Name</Form.Label>
											<Form.Control
												type='text'
												name='otherName'
												placeholder='enter other names'
												value={inputValue.otherName}
												onChange={handleChange}
												isInvalid={!!error.otherName}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.otherName}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Residential Address</Form.Label>
											<Form.Control
												type='text'
												name='residentialAddress'
												placeholder='enter residentialAddress'
												value={inputValue.residentialAddress}
												onChange={handleChange}
												isInvalid={!!error.residentialAddress}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.residentialAddress}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Telephone Number</Form.Label>
											<Form.Control
												type='text'
												name='telephone'
												placeholder='enter other names'
												value={inputValue.telephone}
												onChange={handleChange}
												isInvalid={!!error.telephone}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.telephone}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type='email'
												name='email'
												placeholder='enter email'
												value={inputValue.email}
												onChange={handleChange}
												isInvalid={!!error.email}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.email}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Place of work with address</Form.Label>
											<Form.Control
												type='text'
												name='workPlace'
												placeholder='enter place of work with address'
												value={inputValue.workPlace}
												onChange={handleChange}
												isInvalid={!!error.workPlace}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.workPlace}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Permanent Address</Form.Label>
											<Form.Control
												type='text'
												name='permanentAddress'
												placeholder='enter permanent home address'
												value={inputValue.permanentAddress}
												onChange={handleChange}
												isInvalid={!!error.permanentAddress}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.permanentAddress}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Next of kin</Form.Label>
											<Form.Control
												type='text'
												name='nok'
												placeholder='enter name of nok'
												value={inputValue.nok}
												onChange={handleChange}
												isInvalid={!!error.nok}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.nok}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>NOK phone number</Form.Label>
											<Form.Control
												type='text'
												name='nokPhone'
												placeholder='enter NOK phone'
												value={inputValue.nokPhone}
												onChange={handleChange}
												isInvalid={!!error.nokPhone}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.nokPhone}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>NOK Email</Form.Label>
											<Form.Control
												type='text'
												name='nokEmail'
												placeholder='enter nok email'
												value={inputValue.nokEmail}
												onChange={handleChange}
												isInvalid={!!error.nokEmail}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.nokEmail}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>

									<ButtonGroup>
										<Form.Label className='m-3'>
											Are you a member of LMCS?{" "}
										</Form.Label>

										<ToggleButton
											type='radio'
											name='isMember'
											value={"true"}
											checked={isMember === "true"}
											onChange={(e) => setIsMember(e.currentTarget.value)}>
											Yes{" "}
										</ToggleButton>
										<ToggleButton
											type='radio'
											name='isMember'
											value={"false"}
											checked={isMember === "false"}
											onChange={(e) => setIsMember(e.currentTarget.value)}>
											No{" "}
										</ToggleButton>
									</ButtonGroup>

									{isMember === "true" ? (
										<>
											<Row>
												<Form.Group
													controlId='formdepartment1'
													className='p-2 col-md-6'>
													<Form.Label>Share Capital Balance</Form.Label>
													<Form.Control
														type='text'
														name='shareCapital'
														placeholder='enter current value of your share capital balance'
														value={inputValue.shareCapital}
														onChange={handleChange}
														isInvalid={!!error.shareCapital}
													/>
													<Form.Control.Feedback type='invalid'>
														{error.shareCapital}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group
													controlId='formdepartment1'
													className='p-2 col-md-6'>
													<Form.Label>Ordinary Savings Balance</Form.Label>
													<Form.Control
														type='text'
														name='ordinarySavings'
														placeholder='enter current value of your ordinary savings balance'
														value={inputValue.ordinarySavings}
														onChange={handleChange}
														isInvalid={!!error.ordinarySavings}
													/>
													<Form.Control.Feedback type='invalid'>
														{error.ordinarySavings}
													</Form.Control.Feedback>
												</Form.Group>
											</Row>
											<Row>
												<Form.Group
													controlId='formdepartment1'
													className='p-2 col-md-6'>
													<Form.Label>Retirement Savings Balance</Form.Label>
													<Form.Control
														type='text'
														name='retirementSavings'
														placeholder='enter current value of your retirement savings balance'
														value={inputValue.retirementSavings}
														onChange={handleChange}
													//   isInvalid={!!error.shareCapitalMonths}
													/>
													{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
												</Form.Group>
												<Form.Group
													controlId='formdepartment1'
													className='p-2 col-md-6'>
													<Form.Label>Christmas Savings Balance</Form.Label>
													<Form.Control
														type='text'
														name='christmasSavings'
														placeholder='enter current value of your christmas savings balance'
														value={inputValue.christmasSavings}
														onChange={handleChange}
													//   isInvalid={!!error.shareCapitalMonths}
													/>
													{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
												</Form.Group>
											</Row>
											<Row>
												<Form.Group
													controlId='formdepartment1'
													className='p-2 col-md-6'>
													<Form.Label>Education Savings Balance</Form.Label>
													<Form.Control
														type='text'
														name='educationSavings'
														placeholder='enter current value of your education savings balance'
														value={inputValue.educationSavings}
														onChange={handleChange}
													//   isInvalid={!!error.shareCapitalMonths}
													/>
													{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
												</Form.Group>
											</Row>
											<Row>
												<Form.Group
													controlId='formdepartment1'
													className='p-2 col-md-6'>
													<Form.Label>Land Development Savings Balance</Form.Label>
													<Form.Control
														type='text'
														name='landDevelopmentSavings'
														placeholder='enter current value of your land development savings balance'
														value={inputValue.landDevelopmentSavings}
														onChange={handleChange}
													//   isInvalid={!!error.shareCapitalMonths}
													/>
													{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
												</Form.Group>
											</Row>
										</>
									) : null}

									<br />
									<br />
									{show ? (
										<Alert variant='danger'>
											no signature detected, please add
										</Alert>
									) : null}

									{imageFile?.signature ? (
										<ImagePreview url={imageFile.signatureURL} />
									) : null}
									{imageURL ? (
										<img
											src={imageURL}
											alt='my signature'
											style={{
												display: "block",
												margin: "0 auto",
												border: "1px solid black",
												width: "150px",
											}}
										/>
									) : null}
									<br />

									<Row>
										<Popup
											modal
											trigger={
												<Button className='m-2'> Add E-Signature</Button>
											}
											closeOnDocumentClick={false}
											className='m-2'>
											{(close) => (
												<>
													<SignaturePad
														penColor='black'
														canvasProps={{
															width: 500,
															height: 200,
															className: "sigCanvas",
														}}
														ref={sigCanvas}
													/>
													<Button
														className='m-1 btn-danger'
														onClick={() => sigCanvas.current.clear()}>
														clear
													</Button>
													<Button className='m-1' onClick={close}>
														close
													</Button>
													<Button
														className='m-1'
														variant='success'
														onClick={() => {
															saveCanvas();
															close();
														}}>
														Add
													</Button>
												</>
											)}
										</Popup>
										<p>OR </p>
										<Form.Group
											xs={12}
											md={8}
											controlId='formFile1'
											className='mb-3 col'>
											<Form.Label>Upload Signature</Form.Label>
											<Form.Control
												type='file'
												name='signature'
												onChange={addImage}
												isInvalid={!!error.signature}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.signature}
											</Form.Control.Feedback>
											{imageFile.signatureError ? (
												<Alert variant='danger'>
													{imageFile.signatureError}
												</Alert>
											) : null}
										</Form.Group>
									</Row>

									<Row>
										{" "}
										{imageFile.passport ? (
											<ImagePreview url={imageFile.passportURL} />
										) : null}
										<Form.Group
											xs={12}
											md={8}
											controlId='formFile'
											className='mb-3 col'>
											<Form.Label>Passport</Form.Label>
											<Form.Control
												type='file'
												name='passport'
												onChange={addImage}
												isInvalid={!!error.passport}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.passport}
											</Form.Control.Feedback>

											{imageFile.passportError ? (
												<Alert variant='danger'>
													{imageFile.passportError}
												</Alert>
											) : null}
										</Form.Group>
									</Row>
									<Button
										className='btn btn-primary float-right'
										style={{ marginRight: "5px" }}
										onClick={handlePreview}>
										Preview Guarantor Form
									</Button>
								</Form>
							</div>
						</div>
					</div>
					)
				</Container>
			) : (
				<Preloader />
			)}
		</>
	);
}

export default Guarantor;
